













































import Vue from 'vue'

export default Vue.extend({
  name: 'App',
  data: function () {
    return {
      cookieNotificationVisible: true
    }
  },
  computed: {
    appBarProminent () {
      return this.$vuetify.breakpoint.xsOnly
    }
  },
  beforeMount: function () {
    if (localStorage.getItem('cookieAccepted') === 'true') {
      const t = this as any
      t.cookieNotificationVisible = false
    }
  },
  components: {
  },
  methods: {
    closeCookieNotification: function () {
      localStorage.setItem('cookieAccepted', 'true')
      this.cookieNotificationVisible = false
    }
  }
})
