import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Oferta from '../views/Oferta.vue'
import OMnie from '../views/OMnie.vue'
import Kontakt from '../views/Kontakt.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'oferta',
    component: Oferta
  },
  {
    path: '/omnie',
    name: 'omnie',
    component: OMnie
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: Kontakt
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
