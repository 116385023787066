import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import pl from 'vuetify/src/locale/pl'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        // primary: '#f6b000',
        primary: '#e8a600',
        secondary: '#607d8b',
        accent: '#cddc39',
        error: '#ff5722',
        info: '#03a9f4',
        success: '#8bc34a',
        warning: '#ff9800'
      }
      // light: {
      //   primary: '#525252',
      //   secondary: '#ffc107',
      //   accent: '#cddc39',
      //   error: '#ff5722',
      //   info: '#03a9f4',
      //   success: '#4caf50',
      //   warning: '#ff9800'
      // }
    }
  },
  lang: {
    locales: { pl },
    current: 'pl'
  }
})
